import CartForm from "./CartForm";
// import { Button, Modal } from 'antd';


import { useDispatch } from "react-redux"
import { cartActions } from "../../store/cart-slice"
import { useState } from "react";

import Modal from "react-modal";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

Modal.setAppElement("#root");

let PartsList=(props)=>{
    let dispatch=useDispatch();
    let [partsList,setPartsList]=useState(props.parts)
    let DispathCart=(item,quantity)=>{
        dispatch(cartActions.addItemToCart({item:item,quantity:quantity}))
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    let [imageForView,setImageForView]=useState('')

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    //   };

    let [inputValue,setInputValue]=useState('')

    let searchValueChangeHandler=(e)=>{
            let tempArray=[];
            tempArray=props.parts.filter(item=>item.adminCode.includes(e.target.value)||item.name.includes(e.target.value)||item.brand.includes(e.target.value)||item.description.includes(e.target.value))
            setPartsList(tempArray);
            setInputValue(e.target.value)
    }

    return(<div>

<div className="flex p-4 space-x-4">
    <input type="text" value={inputValue} onChange={searchValueChangeHandler} className="p-1 border border-black rounded-md" placeholder="Search"/>
    {inputValue!==''&&<button className="py-1 px-8 hover:border border-black font-bold bg-primaryColor text-white hover:text-primaryColor hover:bg-white rounded-md" onClick={()=>{setInputValue('');setPartsList(props.parts)}}>Clear</button>} 
</div>

{partsList.length>0?partsList.map((item,key)=>{
                return(
                    <div className="md:flex md:space-x-4 p-2 lg:p-4 w-full border-b border-black" key={key}>
                        {/* Modal for Image Viewing */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Image Viewer"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80"
        overlayClassName="fixed inset-0 bg-black bg-opacity-70"
      >
        <div className="relative bg-white p-4 rounded-lg">
          <button
            className="absolute top-2 right-2 text-white bg-red-500 rounded-full px-3 py-1"
            onClick={() => setIsModalOpen(false)}
          >
            ✕
          </button>
          {/* Zoomable Image */}
          <Zoom>
            <img src={imageForView} alt="Zoomable" className="max-w-full max-h-screen rounded-lg" />
          </Zoom>
        </div>
      </Modal>
                        <div>
                         <h1>Part No. <span className="font-bold"> {item.adminCode}</span></h1> 
                         {console.log('++++++ iamge url',item.image)}  
                        <img src={'https://hashtechcarparts.s3.amazonaws.com/'+item.image+''} onClick={()=>{setImageForView(`https://hashtechcarparts.s3.amazonaws.com/${item.image}`); setIsModalOpen(true);}} className="w-56 h-36" alt={item.image}/>
                            </div>
                         <div>
                         <h1 className="font-bold text-xl">{item.name}</h1>
                         <h1><span className="font-bold">Brand: &nbsp;</span> {item.brand}</h1>
                        
                         <hr/>
                         <h1 className="font-bold">Description</h1>
                         <pre className="">{item.description}</pre>

                         <div className="lg:flex md:space-x-4 ">
                           <CartForm item={item} DispathCart={DispathCart} />
                         </div>
                         </div>
                        
                      
                        </div>
                )
            }):<p className="text-lg text-red-500 p-2">No Parts Found</p>}

    </div>)

}


export default PartsList